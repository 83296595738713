<template>
  <Table fullwidth hoverable bordered scrollable>
    <TableHeader>
      <TableHeaderCell :heading="$t('general.name')" />
      <TableHeaderCell :heading="$t('general.role')" />
      <TableHeaderCell :heading="$t('hunt.assignments.stand')" />
    </TableHeader>

    <TableContent>
      <TableRow v-for="assignment in assignments" :key="assignment.id">
        <TableCell>
          {{ assignment.getFullName() }}
        </TableCell>

        <TableCell :class="{ 'has-text-grey': assignment.role === 'unassigned' }">
          {{ getRoleName(assignment) }}
        </TableCell>

        <TableCell>
          <span v-if="assignment.role === 'pass' && assignment.markerId !== null">
            <div class="level is-mobile">
              <div class="level-left">
                <div class="level-item">
                  <img :src="getMarkerIcon(assignment)" />
                </div>

                <div class="level-item">
                  {{ getMarker(assignment).name }}
                </div>
              </div>
            </div>
          </span>
        </TableCell>
      </TableRow>
    </TableContent>
  </Table>
</template>

<script>
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'

export default {
  props: {
    assignments: {
      type: Array,
      required: true
    },

    markers: {
      type: Array,
      required: true
    }
  },

  methods: {
    getRoleName (assignment) {
      const roles = {
        dog: this.$i18n.t('hunt.roles.dogHandler'),
        drive: this.$i18n.t('hunt.roles.driveHunter'),
        pass: this.$i18n.t('hunt.roles.standShooter'),
        unassigned: this.$i18n.t('hunt.roles.unassigned')
      }

      return assignment.role !== null ? roles[assignment.role] : this.$i18n.t('hunt.roles.unassigned')
    },

    getMarker (assignment) {
      const markers = this.markers || []
      const i = markers.findIndex(marker => marker.id === assignment.markerId)
      return i !== -1 ? markers[i] : null
    },

    getMarkerIcon (assignment) {
      const marker = this.getMarker(assignment)
      return marker !== null ? getMarkerTypeIconUrl(marker.type) : ''
    }
  }
}
</script>
