<template>
  <div class="level is-mobile">
    <div class="level-left">
      <ProfilePicture :userId="Number(member.userId)" size="small" round />
      <span class="push-left-xs" :class="{ 'is-dimmed': hasDeclined }">{{ member.getFullName() }}</span>
    </div>

    <div v-if="showStatus" class="level-right">
      <div v-if="member.status !== null" class="level-item">
        <small v-if="hasAccepted" class="has-text-success">{{ $t('hunt.accepted') }}</small>
        <small v-if="waitingForReply" class="has-text-grey">{{ $t('hunt.waitingForReply') }}</small>
        <small v-if="hasDeclined" class="has-text-danger">{{ $t('hunt.declined') }}</small>
      </div>

      <div class="level-item">
        <Icon
          v-if="removable"
          class="is-clickable has-text-danger"
          name="icon-x-circle"
          @click="onRemove">
        </Icon>

        <span v-else style="width: 20px;"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    member: {
      type: Object,
      default: () => {}
    },

    removable: {
      type: Boolean,
      default: true
    },

    showStatus: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    hasDeclined () {
      return this.member.status === 'declined'
    },

    hasAccepted () {
      return this.member.status === 'member'
    },

    waitingForReply () {
      return this.member.status === 'invited'
    }
  },

  methods: {
    async onRemove () {
      this.$emit('remove')
    }
  }
}
</script>
