<template>
  <div>
    <Heading size="6" :text="driveName" />

    <HuntAssignmentTable
      :markers="markers"
      :assignments="assignments">
    </HuntAssignmentTable>
  </div>
</template>

<script>
import HuntAssignmentTable from './HuntAssignmentTable.vue'

export default {
  components: {
    HuntAssignmentTable
  },

  props: {
    drive: {
      type: Object,
      required: true
    },

    subAreas: {
      type: Array,
      required: true
    },

    markers: {
      type: Array,
      required: true
    }
  },

  computed: {
    subArea () {
      if (this.subAreas !== null) {
        const i = this.subAreas.findIndex(subArea => subArea.id === this.drive.subAreaId)
        return i !== -1 ? this.subAreas[i] : null
      }
      return null
    },

    driveName () {
      if (this.drive.subAreaId !== null) {
        return this.subArea !== null ? this.subArea.name : ''
      }
      return this.$i18n.t('hunt.assignments.entireHuntArea')
    },

    assignments () {
      return this.drive.assignments.slice(0).sort((a, b) => a.getFullName().localeCompare(b.getFullName()))
    }
  }
}
</script>
