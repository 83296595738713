<template>
  <Table bordered fullwidth>
    <TableHeader>
      <TableHeaderCell :heading="$t('hunt.huntableGames.species')" />
      <TableHeaderCell :heading="$t('hunt.huntableGames.comment')" />
    </TableHeader>

    <TableContent>
      <TableRow
        v-for="game in games"
        :key="game.id"
        :game="game">
        <TableCell class="is-narrow game-name">
          {{ game.name }}
        </TableCell>

        <TableCell class="has-text-grey game-description">
          <small>{{ game.note }}</small>
        </TableCell>
      </TableRow>
    </TableContent>
  </Table>
</template>

<script>
export default {
  props: {
    games: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.game-name {
  vertical-align: top !important;
}

.game-description {
  white-space: normal;
}
</style>
