<template>
  <div>
    <FormField :label="$t('report.invitation.title')">
      <InlineLoader v-if="inviteText === null" />

      <div v-else>
        <div v-if="inviteText.length > 0" class="text-is-preformatted">{{ inviteText }}</div>

        <Paragraph
          v-else
          :text="$t('report.invitation.none')"
          class="has-text-grey">
        </Paragraph>
      </div>
    </FormField>

    <Divider />

    <FormField :label="$t('report.invitation.invited')">
      <InlineLoader v-if="members === null" />

      <HuntMemberListItem
        v-for="(member, i) in members"
        :key="i"
        :member="member"
        :removable="false"
        :show-status="true">
      </HuntMemberListItem>
    </FormField>

    <Divider />

    <FormField :label="$t('hunt.huntableGames.title')">
      <InlineLoader v-if="games === null" />

      <HuntGameTable
        v-else-if="games.length > 0"
        :games="games">
      </HuntGameTable>

      <Paragraph v-else :text="$t('hunt.huntableGames.noHuntableGamesSelected')" />
    </FormField>

    <Divider />

    <FormField :label="$t('hunt.assignments.title')">
      <InlineLoader v-if="drives === null" />

      <div v-else-if="drives.length > 0">
        <HuntAssignmentDrive
          v-for="(drive, i) in drives"
          :key="drive.id"
          :subAreas="subAreas"
          :markers="markers"
          :drive="drive"
          :class="{ 'push-bottom-xl': i < drives.length - 1 }">
        </HuntAssignmentDrive>
      </div>

      <Paragraph v-else :text="$t('hunt.assignments.noAssignments')" />
    </FormField>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import HuntMemberListItem from '@/modules/huntArea/hunt/components/shared/HuntMemberListItem.vue'
import HuntGameTable from '@/modules/huntArea/hunt/components/shared/HuntGameTable.vue'
import HuntAssignmentDrive from '@/modules/huntArea/hunt/components/shared/HuntAssignmentDrive.vue'

export default {
  components: {
    HuntMemberListItem,
    HuntGameTable,
    HuntAssignmentDrive
  },

  computed: {
    ...mapGetters({
      inviteText: 'report/invite/getInviteText',
      members: 'report/invite/getMembers',
      games: 'report/invite/getGames',
      drives: 'report/invite/getDrives',
      subAreas: 'map/subAreas/getSubAreas',
      markers: 'map/markers/markers'
    })
  }
}
</script>
